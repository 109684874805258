<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card" v-show="showOrder">
					<div class="card-header">
						Consumer Sale List <span class="badge badge-primary">{{sales.length}}</span>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-sm-12" v-for="sale in sales" style="margin-top: 1%;">
								<div class="card">
									<div class="card-body" @click="orderInfoShow(sale)" style="cursor: pointer;">
										<div class="row">
											<div class="col-md-4 p-2">
												<p>Invoice : {{sale.invoice}}</p>
												<p>Consumer : {{sale.consumerName}}</p>
												<p>Phone Number : {{sale.consumerPhone}}</p>
												<p>Location : {{sale.location}}</p>
											</div>
											<div class="col-md-4 p-2"
												style="background-color: #dddddd;border-radius: 2px;">
												<p>Saller Name : {{sale.sallerName}}</p>
												<p>Saller Email : {{sale.sallerEmail}}</p>
												<p>Transport Name : {{sale.deliveryName}}</p>
												<p>Transport Email : {{sale.deliveryEmail}}</p>
											</div>
											<div class="col-md-4">
												<p>Total Price : {{sale.totalPrice}} Ks</p>
												<p>Order Date : {{sale.date}}</p>
												<p>Assign Date : {{sale.assignDate}}</p>
												<p class="text-capitalize">Status : {{sale.status}}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-show="showInfo">
					<div class="card-header">
						<a href="#" @click="orderShow()"><i class="fas fa-arrow-left"></i></a> Consumer Sale Info List
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-sm-12" >
								<div class="card">
									<div class="card-body" >
										<div class="row">
											<div class="col-md-4 p-2">
												<p>Invoice : {{saleDetail.invoice}}</p>
												<p>Consumer : {{saleDetail.consumerName}}</p>
												<p>Phone Number : {{saleDetail.consumerPhone}}</p>
												<p>Location : {{saleDetail.location}}</p>
											</div>
											<div class="col-md-4 p-2"
												style="background-color: #dddddd;border-radius: 2px;">
												<p>Saller Name : {{saleDetail.sallerName}}</p>
												<p>Saller Email : {{saleDetail.sallerEmail}}</p>
												<p>Transport Name : {{saleDetail.deliveryName}}</p>
												<p>Transport Email : {{saleDetail.deliveryEmail}}</p>
											</div>
											<div class="col-md-4">
												<p>Total Price : {{saleDetail.totalPrice}} Ks</p>
												<p>Order Date : {{saleDetail.date}}</p>
												<p>Assign Date : {{saleDetail.assignDate}}</p>
												<p class="text-capitalize">Status : {{saleDetail.status}}</p>
												<p><button class="btn btn-success" style="width:100%;" v-b-modal.bv-modal-status @click="statusDetail()">Status Change</button></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12" v-for="sale in saleInfo" style="margin-top: 2%;">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-md-3" style="padding-top: 12px;">
												<p class="card-text">{{sale.itemName}}</p>
											</div>
											<div class="col-md-2" style="padding-top: 12px;">
												<p class="card-text">Qty : {{sale.quantity}}</p>
											</div>
											<div class="col-md-3" style="padding-top: 12px;">
												<p class="card-text">Price : {{sale.price}} Ks</p>
											</div>
											<div class="col-md-2" style="padding-top: 12px;">
												<p class="card-text text-capitalize">Status : {{sale.status}}</p>
											</div>
											<div class="col-md-2">
												<button class="btn btn-option text-primary" v-b-modal.bv-modal-infostatus @click="statusInfo(sale)"> <i class="fas fa-edit"></i></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal id="bv-modal-status" ref="my-modal" title="Status" hide-footer>
            <div class="d-block text-center">
                <p class="text-danger">{{this.alert}}</p>
				<select class="form-control" v-model="orderDetailRequest.deliveryId">
                    <option value="">Select Transport</option>
                    <option :value="delivery.id" v-for="delivery in deliveryList">{{delivery.name}}</option>
                </select><br>
				<input type="date" class="form-control" v-model="orderDetailRequest.assignDate"><br>
                <select class="form-control" v-model="orderDetailRequest.status">
                    <option value="reject">Reject</option>
                    <option value="pending">Pending</option>
                    <option value="confirmed">Confirm</option>
                    <option value="shipping">Shipping</option>
                    <option value="completed">Completed</option>
                </select>
            </div>
            <b-button class="mt-3 btn-success" block @click="saleStatusUpdate()">Change</b-button>
        </b-modal>
		<b-modal id="bv-modal-infostatus" ref="my-modal" title="Info Status" hide-footer>
            <div class="d-block text-center">
                <p class="text-danger text-center">{{this.message}}</p>
                <input type="text" class="form-control" v-model="orderInfoRequest.itemName" disabled><br>
				<input type="number" class="form-control" v-model="orderInfoRequest.price" placeholder="Price"><br>
                <input type="number" class="form-control" v-model="orderInfoRequest.quantity"><br>
                <select class="form-control" v-model="orderInfoRequest.status">
                    <option value="pending">Pending</option>
                    <option value="reject">Reject</option>
                    <option value="confirmed">Confirmed</option>
                </select>
            </div>
            <b-button class="mt-3 btn-success" block @click="infoStatusUpdate()">Change</b-button>
        </b-modal>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'sale',
		metaInfo: {
            title: "Sale",
            titleTemplate: "%s ← Htawara Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				isLoading: false,
				fullPage: true,
				showOrder: true,
				showInfo: false,
				alert: "",
				message: "",
				deliveryList: [],
				sales: [],
				saleDetail: [],
				saleInfo: [],
				orderDetailRequest: {
                    id: "",
                    deliveryId: "",
					assignDate: "",
                    status: ""
				},
				orderInfoRequest: {
                    infoId: "",
					itemName: "",
					price: "",
                    quantity: "",
                    status: ""
                },
			}
		},
		computed: {},
		methods: {
			...mapActions({
				ConsumerSaleListAction: 'ConsumerSaleListAction',
				DeliveryListAction: 'DeliveryListAction',
				ConsumerSaleStatusAction: 'ConsumerSaleStatusAction',
				ConsumerInfoStatusAction: 'ConsumerInfoStatusAction'
			}),
			async getDelivery() {
				this.isLoading = true;
				await this.DeliveryListAction().then(res => {
					if (res.status == "success") {
						this.deliveryList = res.data
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			async itemList() {
				this.isLoading = true;
				await this.ConsumerSaleListAction().then(res => {
					if (res.status == "success") {
						this.sales = res.data
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			orderInfoShow(order) {
				this.showOrder = false
				this.showInfo = true
				this.saleDetail = order
				this.saleInfo = order.item
			},
			orderShow() {
				this.showOrder = true
				this.showInfo = false
			},
			statusDetail(){
                this.alert = ""
				this.orderDetailRequest.id = this.saleDetail.id
                this.orderDetailRequest.deliveryId = this.saleDetail.deliveryId == null ? "" : this.saleDetail.deliveryId
				this.orderDetailRequest.assignDate = this.saleDetail.assignDate
                this.orderDetailRequest.status = this.saleDetail.status
            },
            statusInfo(data){
                this.alert = ""
                this.orderInfoRequest.infoId = data.id
                this.orderInfoRequest.itemName = data.itemName
				this.orderInfoRequest.price = data.price
                this.orderInfoRequest.quantity = data.quantity
                this.orderInfoRequest.status = data.status
            },
			async saleStatusUpdate() {
                this.isLoading = true
                let option = this.orderDetailRequest
                await this.ConsumerSaleStatusAction({
                    ...option
                }).then(res => {
					this.itemList()
					this.getDelivery()
					this.$refs['my-modal'].hide()
					this.saleDetail.deliveryName = res.data.deliveryName
					this.saleDetail.deliveryEmail = res.data.deliveryEmail
					this.saleDetail.sallerName = res.data.sallerName
					this.saleDetail.sallerEmail = res.data.sallerEmail
					this.saleDetail.assignDate = res.data.assign_date
					this.saleDetail.status = res.data.status
                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
			async infoStatusUpdate() {
                this.isLoading = true
                let option = this.orderInfoRequest
                await this.ConsumerInfoStatusAction({
                    ...option
                }).then(res => {
					if(res.status == 'fail'){
						this.message = res.message
					}else{
						this.itemList()
						this.getDelivery()
						this.$refs['my-modal'].hide()
						this.saleDetail.totalPrice = res.data.totalPrice
						this.saleInfo = res.data.item
					}

                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            }
		},
		async mounted() {
			this.itemList()
			this.getDelivery()
		}
	}
</script>

<style scoped lang="scss">
</style>