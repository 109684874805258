<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div>
					<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toInfo()">Item Info</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link active">Inventory</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link">Report</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link">Item Log</a>
						</li>
					</ul>
				</div>
				<div class="card">
					<div class="card-header">
						<h6>Inventory <small></small></h6>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-sm-3">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title" style="font-size: 14px;">Total</h5>
										<p class="card-text">{{countList.totalStockQty}}</p>
									</div>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title" style="font-size: 14px;">Available</h5>
										<p class="card-text">{{countList.inStockQty}}</p>
									</div>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title" style="font-size: 14px;">Unavailable</h5>
										<p class="card-text">{{countList.outStockQty}}</p>
									</div>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title" style="font-size: 14px;">Inventory Damage</h5>
										<p class="card-text"> {{countList.inventoryDamage}}</p>
									</div>
								</div>
							</div>
						</div>

						<table class="table" style="margin-top: 2%;">
							<thead class="thead-light">
								<tr>
									<th scope="col">Inventory Date</th>
									<th scope="col">Employee</th>
									<th scope="col">Type</th>
									<th scope="col">Quantity</th>
									<th scope="col">Damaged</th>
									<th scope="col">Remark</th>
									<!-- <th>Options</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="stock in stockList">
									<td>{{stock.date | moment}}</td>
									<td>{{stock.inventoryUser}}</td>
									<td>
										<span v-if="stock.type == 'reduce'">-</span>
										<span v-if="stock.type == 'add'">+</span>
									</td>
									<td>{{stock.quantity}}</td>
									<td>{{stock.damage}}</td>
									<td>
										<button @click="showRemark(stock.remark)" v-b-modal.modal-2 class="btn btn-option text-primary"><i class="fas fa-eye"></i></button>
									</td>
									<!-- <td>
										<button class="btn btn-option text-primary"><i class="fas fa-edit"></i></button>
										<button class="btn btn-option text-danger"><i
												class="fas fa-trash-alt"></i></button>
									</td> -->
								</tr>
							</tbody>
						</table>
						<b-modal id="modal-1" ref="my-modal" hide-footer title="Inventory Form">
							<form v-on:submit.prevent="onSubmit">
								<p class="text-danger text-center">{{message}}</p>
								<div class="d-block">
									<div class="form-group">
										<label for="qty">Type:</label>
										<select v-model="inventoryRequest.type" @change="validationRule()" class="form-control">
											<option value="add"> + </option>
											<option value="reduce"> - </option>
										</select>
									</div>
									<div class="form-group">
										<label for="qty">Quantity:</label>
										<input type="number" v-model="inventoryRequest.quantity"
											@change="validationRule()" class="form-control" id="qty">
										<small class="text-danger">{{validationData.quantity}}</small>
									</div>
									<div class="form-group">
										<label for="damage">Damage Quantity:</label>
										<input type="number" v-model="inventoryRequest.damagedQty" class="form-control"
											id="damage">
									</div>
									<div class="form-group">
										<label for="damage">Remark:</label>
										<textarea v-model="inventoryRequest.remark" class="form-control"
											style="height: 200px;"></textarea>
									</div>
								</div>
								<button class="mt-3 btn btn-primary" style="width: 100%;" block>Submit</button>
							</form>
						</b-modal>
						<b-modal id="modal-2" title="Remark" hide-footer>
							<p>{{remark}}</p>
						</b-modal>
						<button type="submit" v-b-modal.modal-1 class="btn btn-primary float-save">
							<i class="fa fa-plus"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	import moment from 'moment';
	export default {
		name: 'itemInventory',
		metaInfo: {
            title: "Item Inventory",
            titleTemplate: "%s ← Htawara Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				isLoading: false,
				fullPage: true,
				countList: [],
				stockList: [],
				message: '',
				remark: '',
				inventoryRequest: {
					itemId: "",
					type: "add",
					quantity: "",
					damagedQty: "",
					remark: ""
				},
				validationData: {
					quantity: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.inventoryRequest.itemId = to.params.id
					await this.itemInventoryDetail()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				ItemInventoryDetailAction: 'ItemInventoryDetailAction',
				ItemInventoryCountAction: 'ItemInventoryCountAction',
				ItemInventoryStoreAction: 'ItemInventoryStoreAction'
			}),
			async itemInventoryDetail() {
				this.isLoading = true;
				await this.ItemInventoryDetailAction(this.inventoryRequest.itemId).then(res => {
					if (res.status == "success") {
						this.stockList = res.data
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			showRemark(remark) {
				this.remark = remark
			},
			async itemInventoryCount() {
				this.isLoading = true;
				await this.ItemInventoryCountAction(this.inventoryRequest.itemId).then(res => {
					if (res.status == "success") {
						this.countList = res.data
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			toInfo() {
				if (this.inventoryRequest.itemId) {
					this.$router.replace('/item-detail/' + this.inventoryRequest.itemId)
				} else {
					this.$router.replace('/item-create/')
				}
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = {
					itemId: this.inventoryRequest.itemId,
					type: this.inventoryRequest.type,
					quantity: this.inventoryRequest.quantity,
					damagedQty: this.inventoryRequest.damagedQty,
					remark: this.inventoryRequest.remark
				}
				await this.ItemInventoryStoreAction({
					...option
				}).then(res => {
					if(res.status == 'fail'){
						this.message = res.message
					}else{
						this.$refs['my-modal'].hide()
						this.itemInventoryDetail()
						this.itemInventoryCount()
					}
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			validationRule() {
				let isValidation = []
				if(this.inventoryRequest.type == "reduce"){
					this.validationData.quantity = ""
					isValidation.push(false)
				}else{
					if (this.inventoryRequest.quantity == "") {
						this.validationData.quantity = "The item quantity field is required."
						isValidation.push(true)
					} else {
						this.validationData.quantity = ""
						isValidation.push(false)
					}
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			},
		},
		filters: {
			moment: function (date) {
				return moment(date).format('YYYY-MM-DD, h:mm A');
			}
		},
		async mounted() {
			if (this.$route.params.id) {
				this.inventoryRequest.itemId = this.$route.params.id
				this.itemInventoryDetail()
				this.itemInventoryCount()
			}
		}
	}
</script>

<style scoped lang="scss">
</style>