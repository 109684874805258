<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div>
					<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
						<li class="nav-item">
							<a href="#" class="nav-link " @click="toInfo()">Distributor Info</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toContract()">Distributor Contract</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link active">Distributor User</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toPrice()">Price Rule</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toReturn()">Return Item</a>
						</li>
					</ul>
				</div>
				<div class="card">
					<div class="card-header">
						<h6>User <small></small></h6>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-8">
								<div class="card mb-2" v-for="user in distributorUsers">
									<div class="card-body">
										<div class="row">
											<div class="col-md-10">
												<h6 class="card-title">{{user.user.name}}</h6>
												<p class="card-text">{{user.user.email}}</p>
											</div>
											<div class="col-md-1">
												<!-- <button class="btn btn-option text-primary" @click="editForm(user)"><i
														class="fas fa-edit"></i></button> -->
											</div>
											<div class="col-md-1">
												<button @click="deleteForm(user)" v-b-modal.bv-modal-delete
													class="btn btn-option text-danger">
													<i class="fas fa-trash-alt"></i></button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="card mb-2">
									<div class="card-body">
										<form v-on:submit.prevent="onSubmit">
											<div class="form-group">
												<label for="inputEmail">Email</label>
												<select v-model="userRequest.email" @change="validationRule()"
													class="form-control" id="inputEmail">
													<option value="">Select Email</option>
													<option :value="user.id" v-for="user in users">
														{{user.email}}</option>
												</select>
												<small class="text-danger">{{validationData.email}}</small>
											</div>
											<div class="form-group row">
												<div class="col-sm-10">
													<button type="submit" class="btn btn-primary mr-2">Save</button>
													<a class="btn btn-danger text-white"
														@click="cancelForm()">Cancel</a>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal id="bv-modal-delete" ref="delete-modal" hide-footer>
			<template v-slot:modal-title>
				Delete
			</template>
			<div class="d-block text-center">
				<p>Are you sure to delete?</p>
				<small class="text-danger">{{message}}</small>
			</div>
			<b-button class="mt-3 btn-danger" block @click="submitDelete()">Delete</b-button>
		</b-modal>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'distributorUser',
		metaInfo: {
            title: "Distri User",
            titleTemplate: "%s ← Htawara Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				isLoading: false,
				fullPage: true,
				distributorUsers: [],
				users: [],
				message: '',
				userRequest: {
					id: "",
					distributorId: "",
					email: ""
				},
				validationData: {
					email: "",
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.userRequest.distributorId = to.params.id
					await this.distributorUser()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				userForDistributorUserAction: 'userForDistributorUserAction',
				distributorUserStoreAction: 'distributorUserStoreAction',
				distributorUserListAction: 'distributorUserListAction',
				distributorUserDeleteAction: 'distributorUserDeleteAction'
			}),
			async userSelect() {
				this.isLoading = true;
				await this.userForDistributorUserAction(this.userRequest.distributorId).then(res => {
					if (res.status == "success") {
						this.users = res.data
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			async distributorUser() {
				this.isLoading = true;
				await this.distributorUserListAction(this.userRequest.distributorId).then(res => {
					if (res.status == "success") {
						this.distributorUsers = res.data
						this.isLoading = false
					}
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = {
					id: this.userRequest.id,
					distributorId: this.userRequest.distributorId,
					email: this.userRequest.email,
				}
				await this.distributorUserStoreAction({
					...option
				}).then(res => {
					if (res.status == "success") {
						this.distributorUser()
						this.userSelect()
						this.userRequest.email = ""
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			toContract() {
				if (this.userRequest.distributorId) {
					this.$router.replace('/distributor-contract/' + this.userRequest.distributorId)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			toInfo() {
				if (this.userRequest.distributorId) {
					this.$router.replace('/distributor-info/' + this.userRequest.distributorId)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			toPrice() {
				if (this.userRequest.distributorId) {
					this.$router.replace('/distributor-price/' + this.userRequest.distributorId)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			toReturn() {
				if (this.userRequest.id) {
					this.$router.replace('/distributor-return/' + this.userRequest.id)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			deleteForm(user) {
                this.userRequest.id = user.id
                this.message = ""
			},
			async submitDelete(){

                this.isLoading = true
				await this.distributorUserDeleteAction(this.userRequest.id).then(res => {
					this.$refs['delete-modal'].hide()
					this.message = ""
					this.distributorUser()
					this.userSelect()
					this.userRequest.id = ""
					this.isLoading = false
				}).catch(err => this.isLoading = true)
            },
			cancelForm() {
				this.validationData.email = ""
			},
			validationRule() {
				let isValidation = []
				if (this.userRequest.email == "") {
					this.validationData.email = "The email field is required."
					isValidation.push(true)
				} else {
					this.validationData.email = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			if (this.$route.params.id) {
				this.userRequest.distributorId = this.$route.params.id
				this.distributorUser()
			}
			await this.userSelect()
		}
	}
</script>

<style scoped lang="scss">

</style>