<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div>
					<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toInfo()">Distributor Info</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" >Distributor Contract</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toUser()">Distributor User</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toPrice()">Price Rule</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link active" @click="toPrice()">Return Item</a>
						</li>
					</ul>
				</div>
				<div class="card">
					<div class="card-header">
						<h6>Return Item <small></small></h6>
					</div>
					<div class="card-body">
						<div class="col-md-12">
                                <div class="card mb-2" v-for="item in returnItem">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <h6 class="card-title">Item : {{item.name}}</h6>
                                                <p class="card-text"> Qty : {{item.qty}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'distriReturn',
		metaInfo: {
            title: "Distri Return",
            titleTemplate: "%s ← Htawara Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				isLoading: false,
				fullPage: true,
				returnItem: [],
				id: ""
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.id = to.params.id
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				DistributorReturnListAction: 'DistributorReturnListAction',
			}),
			async contractList() {
				this.isLoading = true;
				await this.DistributorReturnListAction(this.id).then(res => {
					if (res.status == "success") {
						this.returnItem = res.data
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			toInfo() {
				if (this.id) {
					this.$router.replace('/distributor-info/' + this.id)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			toUser() {
				if (this.id) {
					this.$router.replace('/distributor-user/' + this.id)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			toPrice() {
				if (this.id) {
					this.$router.replace('/distributor-price/' + this.id)
				} else {
					this.$refs['my-modal'].show()
				}
			},
		},
		async mounted() {
			if (this.$route.params.id) {
				this.id = this.$route.params.id
			}
			this.contractList()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
</style>