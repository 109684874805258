<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div>
					<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
						<li class="nav-item">
							<a href="#" class="nav-link active" >Item Info</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toInventory()">Inventory</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" >Report</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" >Item Log</a>
						</li>
					</ul>
				</div>
				<div class="card">
					<div class="card-header">
						<h6>New Item <small>(Fields in red are required)</small></h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">Item Name</label>
								<div class="col-sm-10">
									<input type="text" v-model="infoRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputCategory" class="col-sm-2 col-form-label text-danger">Category</label>
								<div class="col-sm-10">
									<select v-model="infoRequest.itemCategoryId" @change="validationRule()"
										class="form-control" id="inputCategory">
										<option value="">Select Category</option>
										<option :value="itemCategory.id" v-for="itemCategory in itemCategories">
											{{itemCategory.name}}</option>
									</select>
									<small class="text-danger">{{validationData.itemCategoryId}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputTextarea" class="col-sm-2 col-form-label">Descrption</label>
								<div class="col-sm-10">
									<textarea v-model="infoRequest.description" class="form-control" id="inputTextarea"
										rows="3"></textarea>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputPrice" class="col-sm-2 col-form-label text-danger">Price</label>
								<div class="col-sm-10">
									<input type="number" v-model="infoRequest.price" @blur="validationRule()"
										class="form-control" id="inputPrice">
									<small class="text-danger">{{validationData.price}}</small>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-sm-2">Is Return</div>
								<div class="col-sm-10">
									<div class="form-check">
										<input v-model="infoRequest.return" class="form-check-input" type="checkbox">
									</div>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-sm-2">Promo</div>
								<div class="col-sm-10">
									<div class="form-check">
										<input v-model="infoRequest.promo" class="form-check-input" type="checkbox">
									</div>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-sm-2">Status</div>
								<div class="col-sm-10">
									<div class="form-check">
										<input v-model="infoRequest.status" class="form-check-input" type="checkbox">
									</div>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label">Image</label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readFile">
									<img v-if="infoRequest.image" v-bind:src="infoRequest.image" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image}}</small>
								</div>
							</div>
							<div class="form-group row" v-if="currentImage">
								<label for="inputImage" class="col-sm-2 col-form-label">Cuerrent Image</label>
								<div class="col-sm-10">
									<img :src="env.audioUrl+currentImage" class="banner-image mt-2"/>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<b-modal ref="my-modal" hide-backdrop hide-footer>
			<template v-slot:modal-title>
				Alert
			</template>
			<div class="d-block text-center">
				<p>You must fill out the required fields before making other changes.</p>
			</div>
		</b-modal>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'itemCreate',
		metaInfo: {
            title: "Item Create",
            titleTemplate: "%s ← Htawara Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				itemCategories: [],
				currentImage: "",
				infoRequest: {
					id: "",
					name: "",
					itemCategoryId: "",
					description: "",
					price: "",
					return: "",
					promo: "",
					image: "",
					status: ""
				},
				validationData: {
					name: "",
					itemCategoryId: "",
					price: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.infoRequest.id = to.params.id
					await this.itemDetail()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				getItemCategoryForSelectAction: 'getItemCategoryForSelectAction',
				ItemStoreAction: 'ItemStoreAction',
				ItemDetailAction: 'ItemDetailAction'
			}),
			readFile({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.infoRequest.image = e.target.result
				};
			},
			async getItemCategory() {
				this.isLoading = true;
				await this.getItemCategoryForSelectAction().then(res => {
					if (res.status == "success") {
						this.itemCategories = res.data
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			async itemDetail() {
				this.isLoading = true;
				await this.ItemDetailAction(this.infoRequest.id).then(res => {
					if (res.status == "success") {
						this.infoRequest.name = res.data.name
						this.infoRequest.itemCategoryId = res.data.item_category_id
						this.infoRequest.description = res.data.description
						this.infoRequest.price = res.data.price
						this.infoRequest.return = res.data.is_return
						this.infoRequest.promo = res.data.promo
						this.currentImage = res.data.image
						this.infoRequest.status = res.data.status
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = {
					id: this.infoRequest.id,
					itemCategoryId: this.infoRequest.itemCategoryId,
					name: this.infoRequest.name,
					description: this.infoRequest.description,
					price: this.infoRequest.price,
					return: this.infoRequest.return,
					promo: this.infoRequest.promo,
					status: this.infoRequest.status,
					image: this.infoRequest.image
				}
				await this.ItemStoreAction({
					...option
				}).then(res => {
					this.$router.replace('/item-detail/' + res.data.id)
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			toInventory() {
				if (this.infoRequest.id) {
					this.$router.replace('/item-inventory-detail/' + this.infoRequest.id)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			validationRule() {
				let isValidation = []
				if (this.infoRequest.name == "") {
					this.validationData.name = "The item name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.infoRequest.itemCategoryId == "") {
					this.validationData.itemCategoryId = "The item category field is required."
					isValidation.push(true)
				} else {
					this.validationData.itemCategoryId = ""
					isValidation.push(false)
				}
				if (this.infoRequest.price == "") {
					this.validationData.price = "The price field is required."
					isValidation.push(true)
				} else {
					this.validationData.price = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			if (this.$route.params.id) {
				this.infoRequest.id = this.$route.params.id
				this.itemDetail()
			}
			await this.getItemCategory()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>