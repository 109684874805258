<template>
    <div id="app">
        <div class="page-wrapper default-theme sidebar-bg bg1 toggled">
            <nav id="sidebar" :class="['sidebar-wrapper', {'nav_open': this.open, 'nav_close': this.close},]">
                <div class="sidebar-content">
                    <!-- sidebar-brand  -->
                    <!-- <div class="sidebar-item sidebar-brand">
                        <a href="#"><img class="img-responsive img-rounded" style="width: 80%;"
                                src="../assets/img/logo.png"></a>
                    </div> -->
                    <!-- sidebar-header  -->
                    <div class="sidebar-item sidebar-header d-flex flex-nowrap">
                        <div class="user-pic">
                            <img class="img-responsive img-rounded" src="../assets/img/user.jpg" alt="User picture">
                        </div>
                        <div class="user-info">
                            <span class="user-name">Jhon
                                <strong>Smith</strong>
                            </span>
                            <span class="user-role">Administrator</span>
                            <span class="user-status">
                                <i class="fa fa-circle"></i>
                                <span>Online</span>
                            </span>
                        </div>
                    </div>
                    <div class=" sidebar-item sidebar-menu">
                        <ul>
                            <li>
                                <router-link :class="[{active: url === 'dashboard'}]" to="/dashboard">
                                    <i class="fas fa-tachometer-alt"></i>
                                    <span class="menu-text">Dashboard</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'distributor-list' || url === 'distributor-info' || url === 'distributor-contract' || url === 'distributor-user' || url === 'distributor-price' || url === 'distributor-return'}]" to="/distributor-list">
                                    <i class="fas fa-user"></i>
                                    <span class="menu-text">Distributor</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'item-category-list'}]" to="/item-category-list">
                                    <i class="fas fa-shopping-cart"></i>
                                    <span class="menu-text">Category</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'item-list'}]" to="/item-list">
                                    <i class="fas fa-tag"></i>
                                    <span class="menu-text">Item</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'pricerule-list'}]" to="/pricerule-list">
                                    <i class="fas fa-hand-holding-usd"></i>
                                    <span class="menu-text">Price Rules</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'promo-list'}]" to="/promo-list">
                                    <i class="fas fa-percentage"></i>
                                    <span class="menu-text">Promo</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'location'}]" to="/location">
                                    <i class="fas fa-location-arrow"></i>
                                    <span class="menu-text">Location</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'return-list'}]" to="/return-list">
                                    <i class="fas fa-truck-loading"></i>
                                    <span class="menu-text">D-Return</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'sale-list'}]" to="/sale-list">
                                    <i class="fas fa-shopping-cart"></i>
                                    <span class="menu-text">D-Sales</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'consumer-return-list'}]" to="/consumer-return-list">
                                    <i class="fas fa-truck-loading"></i>
                                    <span class="menu-text">C-Return</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'consumer-sale-list'}]" to="/consumer-sale-list">
                                    <i class="fas fa-shopping-cart"></i>
                                    <span class="menu-text">C-Sales</span>
                                </router-link>
                            </li>
                            <!-- <li>
                                <a href="#">
                                    <i class="fas fa-warehouse"></i>
                                    <span class="menu-text">Warehouse</span>
                                </a>
                            </li> -->
                            <li>
                                <router-link :class="[{active: url === 'employee'}]" to="/employee">
                                    <i class="fas fa-id-card"></i>
                                    <span class="menu-text">Employees</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :class="[{active: url === 'member'}]" to="/member">
                                    <i class="fas fa-id-card"></i>
                                    <span class="menu-text">Members</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- sidebar-menu  -->
                </div>
                <!-- sidebar-footer  -->
                <div class="sidebar-footer">
                    <div class="dropdown">

                        <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-bell"></i>
                            <span class="badge badge-pill badge-warning notification">3</span>
                        </a>
                        <div class="dropdown-menu notifications" aria-labelledby="dropdownMenuMessage">
                            <div class="notifications-header">
                                <i class="fa fa-bell"></i>
                                Notifications
                            </div>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">
                                <div class="notification-content">
                                    <div class="icon">
                                        <i class="fas fa-check text-success border border-success"></i>
                                    </div>
                                    <div class="content">
                                        <div class="notification-detail">Lorem ipsum dolor sit amet consectetur
                                            adipisicing
                                            elit. In totam explicabo</div>
                                        <div class="notification-time">
                                            6 minutes ago
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="dropdown-item" href="#">
                                <div class="notification-content">
                                    <div class="icon">
                                        <i class="fas fa-exclamation text-info border border-info"></i>
                                    </div>
                                    <div class="content">
                                        <div class="notification-detail">Lorem ipsum dolor sit amet consectetur
                                            adipisicing
                                            elit. In totam explicabo</div>
                                        <div class="notification-time">
                                            Today
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="dropdown-item" href="#">
                                <div class="notification-content">
                                    <div class="icon">
                                        <i class="fas fa-exclamation-triangle text-warning border border-warning"></i>
                                    </div>
                                    <div class="content">
                                        <div class="notification-detail">Lorem ipsum dolor sit amet consectetur
                                            adipisicing
                                            elit. In totam explicabo</div>
                                        <div class="notification-time">
                                            Yesterday
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item text-center" href="#">View all notifications</a>
                        </div>
                    </div>
                    <div class="dropdown">
                        <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-envelope"></i>
                            <span class="badge badge-pill badge-success notification">7</span>
                        </a>
                        <div class="dropdown-menu messages" aria-labelledby="dropdownMenuMessage">
                            <div class="messages-header">
                                <i class="fa fa-envelope"></i>
                                Messages
                            </div>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">
                                <div class="message-content">
                                    <div class="pic">
                                        <img src="img/user.jpg" alt="">
                                    </div>
                                    <div class="content">
                                        <div class="message-title">
                                            <strong> Jhon doe</strong>
                                        </div>
                                        <div class="message-detail">Lorem ipsum dolor sit amet consectetur adipisicing
                                            elit. In totam explicabo</div>
                                    </div>
                                </div>

                            </a>
                            <a class="dropdown-item" href="#">
                                <div class="message-content">
                                    <div class="pic">
                                        <img src="img/user.jpg" alt="">
                                    </div>
                                    <div class="content">
                                        <div class="message-title">
                                            <strong> Jhon doe</strong>
                                        </div>
                                        <div class="message-detail">Lorem ipsum dolor sit amet consectetur adipisicing
                                            elit. In totam explicabo</div>
                                    </div>
                                </div>

                            </a>
                            <a class="dropdown-item" href="#">
                                <div class="message-content">
                                    <div class="pic">
                                        <img src="img/user.jpg" alt="">
                                    </div>
                                    <div class="content">
                                        <div class="message-title">
                                            <strong> Jhon doe</strong>
                                        </div>
                                        <div class="message-detail">Lorem ipsum dolor sit amet consectetur adipisicing
                                            elit. In totam explicabo</div>
                                    </div>
                                </div>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item text-center" href="#">View all messages</a>

                        </div>
                    </div>
                    <div class="dropdown">
                        <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-cog"></i>
                            <span class="badge-sonar"></span>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuMessage">
                            <a class="dropdown-item" href="#">My profile</a>
                            <a class="dropdown-item" href="#">Help</a>
                            <a class="dropdown-item" href="#">Setting</a>
                        </div>
                    </div>
                    <div>
                        <a href="#" @click="logout()">
                            <i class="fa fa-power-off"></i>
                        </a>
                    </div>
                    <div class="pinned-footer">
                        <a href="#">
                            <i class="fas fa-ellipsis-h"></i>
                        </a>
                    </div>
                </div>
            </nav>
            <div class="layer" @click="closeNav()" :class="{'layer_open': this.open}"></div>
            <!-- page-content  -->
            <main class="page-content pt-2">
                <label class="icon admin-menu" @click="openNav()" for="check-menu" style="margin-left: 2%;">
                    &#9776;
                </label>
                <router-view />
            </main>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'admin',
        data() {
            return {
                check: true,
                close: true,
                open: false,
                url: ''
            }
        },
        methods: {
            openNav: function () {
                this.open = !this.open
                console.log(this.open)
                if (this.check == true) {
                    this.close = false
                    this.open = true
                }
            },
            closeNav: function () {
                this.open = !this.open
                if (this.open == false) {
                    this.close = true
                    this.open = false
                }
            },
            logout() {
                window.localStorage.setItem('token', null)
                this.$router.replace('/login')
            }
        },
        watch: {
            '$route'(to, from) {
                this.url = to.name
            }
        },
        mounted() {
            this.url = this.$route.name
        }
    }
</script>
<style lang="scss">
    @import "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css";
    @import "https://use.fontawesome.com/releases/v5.8.2/css/all.css";
    @import "../assets/css/main.css";
    @import "../assets/css/sidebar-themes.css";

    ::-webkit-scrollbar {
        width: 6px;
        background-color: #525965;
    }

    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 50px;
        right: 2%;
        background-color: #0C9;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-save {
        position: fixed;
        bottom: 50px;
        right: 2%;
        background-color: #007bffC9;
        color: #FFF;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-filter {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 120px;
        right: 2%;
        background-color: #318be3;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .btn-option {
        border: none;
        background: none;
        font-size: 24px;
    }

    .btn:focus,
    .btn:active:focus,
    .btn.active:focus {
        box-shadow: none !important;
    }

    .modal-backdrop {
        opacity: 0.5 !important;
    }

    /* Add a black background color to the top navigation */
    .topnav {
        background-color: #333;
        overflow: hidden;
    }

    /* Style the links inside the navigation bar */
    .topnav a {
        float: left;
        display: block;
        color: #f2f2f2;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
    }

    /* Change the color of links on hover */
    .topnav a:hover {
        background-color: #ddd;
        color: black;
    }

    /* Add an active class to highlight the current page */
    .topnav a.active {
        background-color: #4CAF50;
        color: white;
    }

    /* Hide the link that should open and close the topnav on small screens */
    .topnav .icon {
        display: none;
    }
    .active {
        color: #20c997 !important;
    }
</style>