import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import env from '../enviorments'
import header from '../header'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        token: null,
        user: null,
    },
    getters: {

    },
    mutations: {
        mutateUser(state, user) {
            state.user = user
        },
        mutateToken(state, token) {
            state.token = token
        },
    },
    actions: {
        async userLogin({commit}, user) {
            const result = (await axios.post(env.apiUrl + 'admin-user-login', {...user})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            window.localStorage.setItem('token', JSON.stringify(result.data.token))
            result.data.password = null,
            window.localStorage.setItem('user', JSON.stringify(result.data))
            commit('mutateToken', result.token)
            commit('mutateUser', result.data)
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Dashboard
        async dashboardAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'dashboard', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Item Category
        async ItemCategoryListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'item-category-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ItemCategoryStoreAction({commit}, category) {
            const result = (await axios.post(env.apiUrl + 'item-category-store', {...category}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async itemCategoryDeleteAction({commit}, category) {
            const result = (await axios.post(env.apiUrl + 'item-category-delete', {...category}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Item
        async getItemCategoryForSelectAction({commit}, user) {
            const result = (await axios.get(env.apiUrl + 'item-category-select', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ItemListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'item-list', {params: {...option},headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ItemDetailAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'item-detail/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ItemInventoryCountAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'item-inventory-count/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ItemInventoryDetailAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'item-inventory-detail/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ItemStoreAction({commit}, item) {
            const result = (await axios.post(env.apiUrl + 'item-store', {...item}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ItemInventoryStoreAction({commit}, item) {
            const result = (await axios.post(env.apiUrl + 'item-inventory-store', {...item}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Location
        async LocationListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'location-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async LocationStoreAction({commit}, location) {
            const result = (await axios.post(env.apiUrl + 'location-store', {...location}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Distributor
        async DistributorListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'distributor-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async LocationListForSelectAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'location-list-select', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async DistributorInfoStoreAction({commit}, distributor) {
            const result = (await axios.post(env.apiUrl + 'distributor-store', {...distributor}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async DistributorInfoAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'distributor-info/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async userForDistributorUserAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'user-list-select/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async distributorUserListAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'distributor-user-list/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async distributorUserStoreAction({commit}, location) {
            const result = (await axios.post(env.apiUrl + 'distributor-user-store', {...location}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async distributorUserStoreAction({commit}, location) {
            const result = (await axios.post(env.apiUrl + 'distributor-user-store', {...location}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async distributorUserDeleteAction({commit}, id) {
            const result = (await axios.delete(env.apiUrl + 'distributor-user-delete/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async DistributorContractListAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'distributor-contract-list/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async priceSelectAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'pricerule-select/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async distributorPriceAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'distributor-pricerule/'+ id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async distributorPriceStoreAction({commit}, price) {
            const result = (await axios.post(env.apiUrl + 'distributor-pricerule-store', {...price}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async distributorPriceDeleteAction({commit}, id) {
            const result = (await axios.delete(env.apiUrl + 'distributor-pricerule-delete/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ItemSelectAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'item-list-select', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async DistributorReturnListAction({commit}, id) {
            const result = (await axios.get(env.apiUrl + 'distributor-return-list/'+id, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Price Rule
        async PriceListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'pricerule-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async PriceStoreAction({commit}, price) {
            const result = (await axios.post(env.apiUrl + 'pricerule-store', {...price}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Promo
        async PromoListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'promo-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async PromoStoreAction({commit}, price) {
            const result = (await axios.post(env.apiUrl + 'promo-store', {...price}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Sale
        async SaleListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'order-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Rerturn
        async ReturnListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'return-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async returnStoreAction({commit}, data) {
            const result = (await axios.post(env.apiUrl + 'return-store', {...data}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Consumer Sale
        async ConsumerSaleListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'consumer-order-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async DeliveryListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'delivery-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ConsumerSaleStatusAction({commit}, data) {
            const result = (await axios.post(env.apiUrl + 'consumer-sale-status', {...data}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ConsumerInfoStatusAction({commit}, data) {
            const result = (await axios.post(env.apiUrl + 'consumer-info-status', {...data}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Consumer Rerturn
        async ConsumerReturnListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'consumer-return-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async ConsumerReturnStoreAction({commit}, data) {
            const result = (await axios.post(env.apiUrl + 'consumer-return-store', {...data}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Employee
        async employeeListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'employee-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async employeeStoreAction({commit}, employee) {
            const result = (await axios.post(env.apiUrl + 'employee-store', {...employee}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Member
        async distributorForMemberAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'distributor-for-member', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async memberListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'member-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async memberStoreAction({commit}, data) {
            const result = (await axios.post(env.apiUrl + 'member-store', {...data}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        
    },
    modules: {
    }
})
