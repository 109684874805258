<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Employee List <span class="badge badge-primary">{{employees.length}}</span>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="card mb-2" v-for="employee in employees">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-11">
                                                <h6 class="card-title">{{employee.name}}  
                                                    <small class="text-success">
                                                        ( {{employee.roles[0].name}} )
                                                    </small></h6>
                                                <p class="card-text">{{employee.email}}</p>
                                            </div>
                                            <div class="col-md-1">
                                                <button class="btn btn-option text-primary" @click="editForm(employee)"><i class="fas fa-edit"></i></button>
                                            </div>
                                            <!-- <div class="col-md-1">
                                                <button @click="deleteForm(employee)" v-b-modal.bv-modal-delete class="btn btn-option text-danger"><i class="fas fa-trash-alt"></i></button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card mb-2">
                                    <div class="card-body">
                                        <form v-on:submit.prevent="onSubmit">
                                            <div class="form-group">
                                                <label for="inputName">Name</label>
                                                <input type="text" v-model="employeeRequest.name"
                                                    @blur="validationRule()" class="form-control" id="inputName">
                                                <small class="text-danger">{{validationData.name}}</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputLat">Email</label>
                                                <input type="text" v-model="employeeRequest.email"
                                                    @blur="validationRule()" class="form-control" id="inputLat">
                                                <small class="text-danger">{{validationData.email}}</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputLong">Role</label>
                                                <select v-model="employeeRequest.role" @change="validationRule()"
                                                    class="form-control" id="inputPrice">
                                                    <option value="">Select Role</option>
                                                    <option value="1">Admin</option>
                                                    <option value="7">Warehouse</option>
                                                    <option value="8">Direct Sale</option>
                                                    <option value="2">Saller</option>
                                                    <option value="3">Transport</option>
                                                    <option value="4">Distributor</option>
                                                </select>
                                                <small class="text-danger">{{validationData.role}}</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputLat">Password</label>
                                                <input type="password" v-model="employeeRequest.password"
                                                    @blur="validationRule()" class="form-control" id="inputLat">
                                                <small class="text-danger">{{validationData.password}}</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputLat">Re-Type Password</label>
                                                <input type="password" v-model="employeeRequest.repassword"
                                                    @blur="validationRule()" class="form-control" id="inputLat">
                                                <small class="text-danger">{{validationData.repassword}}</small>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-10">
                                                    <button type="submit" class="btn btn-primary mr-2">Save</button>
                                                    <a class="btn btn-danger text-white" @click="cancelForm()">Cancel</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" hide-footer>
            <template v-slot:modal-title>
                Delete
            </template>
            <div class="d-block text-center">
                <p>Are you sure to delete?</p>
                    <small class="text-danger">{{message}}</small>
            </div>
            <b-button class="mt-3 btn-danger" block @click="loactionDelete()">Delete</b-button>
        </b-modal>
        <!-- <router-link class="float-filter" to="">
            <i class="fa fa-filter my-float" style="padding: 37%;bottom: 50px;"></i>
        </router-link> -->
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'employee',
        metaInfo: {
            title: "Employee",
            titleTemplate: "%s ← Htawara Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                message: "",
                employees: [],
                employeeRequest: {
                    id: "",
                    name: "",
                    email: "",
                    password: "",
                    repassword: "",
                    role: ""
                },
                validationData: {
                    name: "",
                    email: "",
                    role: "",
                    password: "",
                    repassword: ""
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                employeeListAction: 'employeeListAction',
                employeeStoreAction: 'employeeStoreAction'
            }),
            async employeeList() {
                this.isLoading = true;
                await this.employeeListAction().then(res => {
                    if (res.status == "success") {
                        this.employees = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async onSubmit() {
                this.isLoading = true
                let validation = this.validationRule()
                if (validation == true) {
                    this.isLoading = false
                    return
                }
                let option = {
                    id: this.employeeRequest.id,
                    name: this.employeeRequest.name,
                    email: this.employeeRequest.email,
                    role: this.employeeRequest.role,
                    password: this.employeeRequest.password

                }
                await this.employeeStoreAction({
                    ...option
                }).then(res => {
                    if (res.status == 'success') {
                        this.employeeList()
                        this.cancelForm()
                    }
                    if (res.status === 'fail'){
                        this.employeeList()
                        this.validationData.email = "The email field already exit!"
                    }
                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
            editForm(employee) {
                this.employeeRequest.id = employee.id
                this.employeeRequest.name = employee.name
                this.employeeRequest.email = employee.email
                this.employeeRequest.role = employee.roles[0].id
            },
            deleteForm(employee) {
                this.employeeRequest.id = employee.id
                this.message = ""
            },
            cancelForm() {
                this.employeeRequest.id = ""
                this.employeeRequest.name = ""
                this.employeeRequest.email = ""
                this.employeeRequest.role = ""
                this.validationData.name = ""
                this.validationData.email = ""
                this.validationData.role = ""
            },
            validationRule() {
                let isValidation = []
                if (this.employeeRequest.name == "") {
                    this.validationData.name = "The name field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.name = ""
                    isValidation.push(false)
                }
                if (this.employeeRequest.email == "") {
                    this.validationData.email = "The email field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.email = ""
                    isValidation.push(false)
                }
                if (this.employeeRequest.role == "") {
                    this.validationData.role = "The role field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.role = ""
                    isValidation.push(false)
                }
                 if(this.employeeRequest.id == ""){
                    if(this.employeeRequest.password == ""){
                        this.validationData.password = "The password field is required."
                        isValidation.push(true)
                    }else{
                        this.validationData.password = ""
                        isValidation.push(false)
                    }
                    if(this.employeeRequest.repassword == ""){
                        this.validationData.repassword = "The repassword field is required."
                        isValidation.push(true)
                    }else{
                        this.validationData.repassword = ""
                        isValidation.push(false)
                    }
                    if(this.employeeRequest.password != this.employeeRequest.repassword){
                        this.validationData.repassword = "The password field does not match!"
                        isValidation.push(true)
                    }else{
                        this.validationData.repassword = ""
                        isValidation.push(false)
                    }
                }
                if (isValidation.includes(true)) {
                    return true
                } else {
                    return false
                }
            }
        },
        async mounted() {
            this.employeeList()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>