<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card" v-show="showOrder">
					<div class="card-header">
						Consumer Return List <span class="badge badge-primary">{{sales.length}}</span>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-sm-12" v-for="sale in sales" style="margin-top: 2%;">
								<div class="card">
									<div class="card-body" @click="orderInfoShow(sale)" style="cursor: pointer;">
										<div class="row">
											<div class="col-md-4 p-2">
												<p>Consumer : {{sale.consumerName}}</p>
												<p>Phone Number : {{sale.consumerPhone}}</p>
												<p>Location : {{sale.location}}</p>
											</div>
											<div class="col-md-4 p-2"
												style="background-color: #dddddd;border-radius: 2px;">
												<p>Saller Name : {{sale.sallerName}}</p>
												<p>Saller Email : {{sale.sallerEmail}}</p>
												<p>Transport Name : {{sale.deliveryName}}</p>
												<p>Transport Email : {{sale.deliveryEmail}}</p>
											</div>
											<div class="col-md-4">
												<p class="text-capitalize">Status : {{sale.status}}</p>
												<p>Date : {{sale.date}}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-show="showInfo">
					<div class="card-header">
						<a href="#" @click="orderShow()"><i class="fas fa-arrow-left"></i></a> Consumer Return Info List
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-sm-12" v-for="sale in saleInfo" style="margin-top: 2%;">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-md-3">
												<h5 class="card-title">{{sale.itemName}}</h5>
											</div>
											<div class="col-md-3">
												<p class="card-text">Qty : {{sale.quantity}}</p>
											</div>
											<div class="col-md-3">
												<p class="card-text">Transport Damage : 
													<input type="number" class="form-control" style="width: 50%;" v-model="sale.transportDamage">
												</p>
											</div>
													<div class="col-md-3">
												<p class="card-text">Consumer Damage : {{sale.distriDamage}}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-12" style="margin-top: 2%;">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-md-12">
												<h5 class="card-title">Consumer Remark</h5>
												<textarea class="form-control" disabled>{{consumerRemark}}</textarea>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-12" style="margin-top: 2%;">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-md-12">
												<h5 class="card-title">Transport Remark</h5>
												<textarea class="form-control" v-model="transportRemark"></textarea>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <router-link class="float-filter" to="">
			<i class="fa fa-filter my-float" style="padding: 37%;"></i>
		</router-link> -->
		<button type="submit" @click="onSubmit()" class="btn btn-primary float-save" v-show="showInfo">Save</button>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'Return',
		metaInfo: {
            title: "Return",
            titleTemplate: "%s ← Htawara Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				isLoading: false,
				fullPage: true,
				showOrder: true,
				showInfo: false,
				sales: [],
				saleInfo: [],
				returnId: "",
				transportRemark: "",
				consumerRemark: ""
			}
		},
		computed: {},
		methods: {
			...mapActions({
				ConsumerReturnListAction: 'ConsumerReturnListAction',
				ConsumerReturnStoreAction: 'ConsumerReturnStoreAction'
			}),
			async itemList() {
				this.isLoading = true;
				await this.ConsumerReturnListAction().then(res => {
					if (res.status == "success") {
						this.sales = res.data
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
                this.isLoading = true
                let option = {
					returnId: this.returnId,
                    remark: this.transportRemark,
                    returnItem: this.saleInfo
                }
                await this.ConsumerReturnStoreAction({
                    ...option
                }).then(res => {
					this.itemList()
                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
			orderInfoShow(order) {
				this.showOrder = false
				this.showInfo = true
				this.returnId = order.id
				this.saleInfo = order.item
				this.transportRemark = order.transportRemark
				this.consumerRemark = order.consumerRemark
			},
			orderShow() {
				this.showOrder = true
				this.showInfo = false
			}
		},
		async mounted() {
			this.itemList()
		}
	}
</script>

<style scoped lang="scss">
</style>