<template lang="html">
    <div class="container-fluid p-5">
        <div class="row">
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Pending Orders</h5>
                        <p class="card-text">{{dashboard.pending}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Approved Orders</h5>
                        <p class="card-text">{{dashboard.approve}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Shipping Orders</h5>
                        <p class="card-text">{{dashboard.shipping}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Completed Orders</h5>
                        <p class="card-text">{{dashboard.complete}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Sales</h5>
                        <p class="card-text">{{dashboard.sale}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Transporters</h5>
                        <p class="card-text">{{dashboard.transporter}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Distributors</h5>
                        <p class="card-text">{{dashboard.distributor}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Members</h5>
                        <p class="card-text">0</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12">
                <h5>Transporters Schedules</h5>
                <hr>
            </div>
            <div class="col-md-3" v-for="schedule in dashboard['schedule']">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">{{schedule.name}}</h5>
                        <p class="card-text">
                            <button type="button" class="btn btn-success">
                                Order <span class="badge badge-light">{{schedule.transporter_order.length}}</span>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'dashboard',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                dashboard: [],
            }
        },
        computed: {
            ...mapState({})
        },
        methods: {
            ...mapActions({
                dashboardAction: 'dashboardAction',
            }),
            async dashboardDetail() {
                this.isLoading = true;
                await this.dashboardAction().then(res => {
                    if (res.status == "success") {
                        this.dashboard = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
        },
        async mounted() {
            this.isLoading = true
            this.dashboardDetail()
        }
    }
</script>


<style scoped lang="scss">
</style>