<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Price Rule List <span class="badge badge-primary">{{prices.length}}</span>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="card mb-2" v-for="price in prices">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <h6 class="card-title">{{price.name}}</h6>
                                                <p class="card-text">{{price.price}}</p>
                                            </div>
                                            <div class="col-md-1">
                                                <button class="btn btn-option text-primary" @click="editForm(price)"><i
                                                        class="fas fa-edit"></i></button>
                                            </div>
                                            <div class="col-md-1">
                                                <button @click="deleteForm(price)" v-b-modal.bv-modal-delete
                                                    class="btn btn-option text-danger"><i
                                                        class="fas fa-trash-alt"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card mb-2">
                                    <div class="card-body">
                                        <form v-on:submit.prevent="onSubmit">
                                            <div class="form-group">
                                                <label for="inputName">Name</label>
                                                <input type="text" v-model="priceRequest.name" @blur="validationRule()"
                                                    class="form-control" id="inputName">
                                                <small class="text-danger">{{validationData.name}}</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputPrice">Item</label>
                                                <select v-model="priceRequest.itemId" @change="validationRule()"
                                                    class="form-control" id="inputPrice">
                                                    <option value="">Select Item</option>
                                                    <option :value="item.id" v-for="item in items">
                                                        {{item.name}}</option>
                                                </select>
                                                <small class="text-danger">{{validationData.itemId}}</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputLong">price</label>
                                                <input type="text" v-model="priceRequest.price" @blur="validationRule()"
                                                    class="form-control" id="inputLong">
                                                <small class="text-danger">{{validationData.price}}</small>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-10">
                                                    <button type="submit" class="btn btn-primary mr-2">Save</button>
                                                    <a class="btn btn-danger text-white"
                                                        @click="cancelForm()">Cancel</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" hide-footer>
            <template v-slot:modal-title>
                Delete
            </template>
            <div class="d-block text-center">
                <p>Are you sure to delete?</p>
                <small class="text-danger">{{message}}</small>
            </div>
            <b-button class="mt-3 btn-danger" block @click="loactionDelete()">Delete</b-button>
        </b-modal>
        <b-modal id="bv-modal-search" ref="my-modal" title="Filter" hide-footer>
            <div class="d-block text-center">
                <input class="form-control" placeholder="Price Rule Name" v-model="filter.name">
            </div>
            <b-button class="mt-3 btn-success" block @click="search()">Search</b-button>
        </b-modal>
        <router-link class="float-filter" to="" v-b-modal.bv-modal-search>
            <i class="fa fa-filter my-float" style="padding: 37%;bottom: 50px;"></i>
        </router-link>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'pricerule',
        metaInfo: {
            title: "Price Rule",
            titleTemplate: "%s ← Htawara Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                message: "",
                prices: [],
                items: [],
                filter: {
                    page: 1,
                    reverse: "desc",
                    sort: "created_at",
                    name: "",
                },
                priceRequest: {
                    id: "",
                    name: "",
                    itemId: "",
                    price: ""
                },
                validationData: {
                    name: "",
                    itemId: "",
                    price: ""
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                ItemSelectAction: 'ItemSelectAction',
                PriceListAction: 'PriceListAction',
                PriceStoreAction: 'PriceStoreAction'
            }),
            async ItemSelect() {
                this.isLoading = true;
                await this.ItemSelectAction().then(res => {
                    if (res.status == "success") {
                        this.items = res.data
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            async search() {
                this.filter.page = 1
                this.priceList()
                this.$refs['my-modal'].hide()
            },
            async priceList() {
                this.isLoading = true;
                let option = {
					page: this.filter.page,
					reverse: this.filter.reverse,
					sort: this.filter.sort,
                    name: this.filter.name
                }
				await this.PriceListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
						this.prices = res.data.data
						this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            async onSubmit() {
                this.isLoading = true
                let validation = this.validationRule()
                if (validation == true) {
                    this.isLoading = false
                    return
                }
                let option = {
                    id: this.priceRequest.id,
                    name: this.priceRequest.name,
                    itemId: this.priceRequest.itemId,
                    price: this.priceRequest.price

                }
                await this.PriceStoreAction({
                    ...option
                }).then(res => {
                    this.priceList()
                    this.cancelForm()
                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
            editForm(price) {
                this.priceRequest.id = price.id
                this.priceRequest.name = price.name
                this.priceRequest.itemId = price.item_id
                this.priceRequest.price = price.price
            },
            deleteForm(price) {
                this.priceRequest.id = category.id
                this.message = ""
            },
            cancelForm() {
                this.priceRequest.id = ""
                this.priceRequest.name = ""
                this.priceRequest.itemId = ""
                this.priceRequest.price = ""
                this.validationData.name = ""
                this.validationData.itemId = ""
                this.validationData.price = ""
            },
            validationRule() {
                let isValidation = []
                if (this.priceRequest.name == "") {
                    this.validationData.name = "The location name field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.name = ""
                    isValidation.push(false)
                }
                if (this.priceRequest.itemId == "") {
                    this.validationData.itemId = "The item field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.itemId = ""
                    isValidation.push(false)
                }
                if (this.priceRequest.price == "") {
                    this.validationData.price = "The price field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.price = ""
                    isValidation.push(false)
                }
                if (isValidation.includes(true)) {
                    return true
                } else {
                    return false
                }
            }
        },
        async mounted() {
            this.priceList()
            this.ItemSelect()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>