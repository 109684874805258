<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						Item List <span class="badge badge-primary">{{items.length}}</span>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-sm-4" v-for="item in items" style="margin-top: 2%;">
								<div class="card">
									<div class="card-body">
										<h6 class="card-title">{{item.name}}</h6>
										<p class="card-text">{{item.item_category.name}}</p>
										 <router-link :to="'/item-detail/'+ item.id" class="btn btn-primary">
											<i class="fas fa-edit"></i>
											<span class="menu-text"> Go Edit</span>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal id="bv-modal-search" ref="my-modal" hide-footer>
            <template v-slot:modal-title>
                Filter
            </template>
            <div class="d-block text-center">
                <input class="form-control" placeholder="Item Name" v-model="filter.name">
            </div>
            <b-button class="mt-3 btn-success" block @click="search()">Search</b-button>
        </b-modal>
		<router-link class="float-filter" to="" v-b-modal.bv-modal-search>
			<i class="fa fa-filter my-float" style="padding: 37%;"></i>
		</router-link>
		<router-link class="float" to="item-create">
			<i class="fa fa-plus my-float" style="padding: 37%;"></i>
		</router-link>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'itemList',
		metaInfo: {
            title: "Item List",
            titleTemplate: "%s ← Htawara Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				isLoading: false,
				fullPage: true,
				items: [],
				filter: {
                    page: 1,
                    reverse: "desc",
                    sort: "created_at",
                    name: "",
                },
			}
		},
		computed: {},
		methods: {
			...mapActions({
				ItemListAction: 'ItemListAction',
			}),
			async search() {
                this.filter.page = 1
                this.itemList()
                this.$refs['my-modal'].hide()
            },
			async itemList() {
				this.isLoading = true;
				let option = {
					page: this.filter.page,
					reverse: this.filter.reverse,
					sort: this.filter.sort,
                    name: this.filter.name
                }
				await this.ItemListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
						this.items = res.data.data
						this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
			}
		},
		async mounted() {
			this.itemList()
		}
	}
</script>

<style scoped lang="scss">
</style>