<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card" v-show="showOrder">
					<div class="card-header">
						Distributor Sale List <span class="badge badge-primary">{{sales.length}}</span>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-sm-12" v-for="sale in sales" style="margin-top: 1%;">
								<div class="card">
									<div class="card-body" @click="orderInfoShow(sale)" style="cursor: pointer;">
										<div class="row">
											<div class="col-md-4 p-2">
												<p>Invoice : {{sale.invoice}}</p>
												<p>Distributor : {{sale.distributorName}}</p>
												<p>Phone Number : {{sale.distributorPhone}}</p>
												<p>Location : {{sale.location}}</p>
											</div>
											<div class="col-md-4 p-2"
												style="background-color: #dddddd;border-radius: 2px;">
												<p>Saller Name : {{sale.sallerName}}</p>
												<p>Saller Email : {{sale.sallerEmail}}</p>
												<p>Transport Name : {{sale.deliveryName}}</p>
												<p>Transport Email : {{sale.deliveryEmail}}</p>
											</div>
											<div class="col-md-4">
												<p>Total Price : {{sale.totalPrice}} Ks</p>
												<p>Date : {{sale.date}}</p>
												<p class="text-capitalize">Status : {{sale.status}}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-show="showInfo">
					<div class="card-header">
						<a href="#" @click="orderShow()"><i class="fas fa-arrow-left"></i></a> Distributor Sale Info List
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-sm-12" >
								<div class="card">
									<div class="card-body" >
										<div class="row">
											<div class="col-md-4 p-2">
												<p>Invoice : {{saleDetail.invoice}}</p>
												<p>Distributor : {{saleDetail.distributorName}}</p>
												<p>Phone Number : {{saleDetail.distributorPhone}}</p>
												<p>Location : {{saleDetail.location}}</p>
											</div>
											<div class="col-md-4 p-2"
												style="background-color: #dddddd;border-radius: 2px;">
												<p>Saller Name : {{saleDetail.sallerName}}</p>
												<p>Saller Email : {{saleDetail.sallerEmail}}</p>
												<p>Transport Name : {{saleDetail.deliveryName}}</p>
												<p>Transport Email : {{saleDetail.deliveryEmail}}</p>
											</div>
											<div class="col-md-4">
												<p>Total Price : {{saleDetail.totalPrice}} Ks</p>
												<p>Date : {{saleDetail.date}}</p>
												<p class="text-capitalize">Status : {{saleDetail.status}}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12" v-for="sale in saleInfo" style="margin-top: 2%;">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-md-3">
												<h5 class="card-title">{{sale.itemName}}</h5>
											</div>
											<div class="col-md-3">
												<p class="card-text">Qty : {{sale.quantity}}</p>
											</div>
											<div class="col-md-3">
												<p class="card-text">Price : {{sale.price}} Ks</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <router-link class="float-filter" to="">
			<i class="fa fa-filter my-float" style="padding: 37%;"></i>
		</router-link>
		<router-link class="float" to="item-create">
			<i class="fa fa-plus my-float" style="padding: 37%;"></i>
		</router-link> -->
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'sale',
		metaInfo: {
            title: "Sale",
            titleTemplate: "%s ← Htawara Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				isLoading: false,
				fullPage: true,
				showOrder: true,
				showInfo: false,
				sales: [],
				saleDetail: [],
				saleInfo: []
			}
		},
		computed: {},
		methods: {
			...mapActions({
				SaleListAction: 'SaleListAction',
			}),
			async itemList() {
				this.isLoading = true;
				await this.SaleListAction().then(res => {
					if (res.status == "success") {
						this.sales = res.data
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			orderInfoShow(order) {
				this.showOrder = false
				this.showInfo = true
				this.saleDetail = order
				this.saleInfo = order.item
			},
			orderShow() {
				this.showOrder = true
				this.showInfo = false
			}
		},
		async mounted() {
			this.itemList()
		}
	}
</script>

<style scoped lang="scss">
</style>