<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Promo List <span class="badge badge-primary">{{promos.length}}</span>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="card mb-2" v-for="promo in promos">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <h6 class="card-title">{{promo.itemName}}
                                                    <small class="text-success" v-if="promo.type == 'fix'">
                                                        ( {{promo.fix}} Ks Off )
                                                    </small>
                                                    <small class="text-success" v-if="promo.type == 'percent'">
                                                        ( {{promo.percent}}% Off )
                                                    </small>
                                                </h6>
                                                <p class="card-text">
                                                    {{promo.startDate | moment}} <i class="fas fa-random"></i>  {{promo.endDate | moment}}
                                                </p>
                                            </div>
                                            <div class="col-md-1">
                                                <button class="btn btn-option text-primary" @click="editForm(promo)"><i
                                                        class="fas fa-edit"></i></button>
                                            </div>
                                            <div class="col-md-1">
                                                <button @click="deleteForm(promo)" v-b-modal.bv-modal-delete
                                                    class="btn btn-option text-danger"><i
                                                        class="fas fa-trash-alt"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card mb-2">
                                    <div class="card-body">
                                        <form v-on:submit.prevent="onSubmit">
                                            <div class="form-group">
                                                <label for="inputName">Type</label>
                                                <select v-model="promoRequest.promoType" @change="validationRule()"
                                                    class="form-control" id="inputPrice">
                                                    <option value="">Select Type</option>
                                                    <option value="percent">Percent</option>
                                                    <option value="fix">Fix</option>
                                                </select>
                                                <small class="text-danger">{{validationData.promoType}}</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputPrice">Item</label>
                                                <select v-model="promoRequest.itemId" @change="validationRule()"
                                                    class="form-control" id="inputPrice">
                                                    <option value="">Select Item</option>
                                                    <option :value="item.id" v-for="item in items">
                                                        {{item.name}}</option>
                                                </select>
                                                <small class="text-danger">{{validationData.itemId}}</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputLong">Promo Value</label>
                                                <input type="number" v-model="promoRequest.promo"
                                                    @blur="validationRule()" class="form-control" id="inputLong">
                                                <small class="text-danger">{{validationData.promo}}</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputLong">Start Date</label>
                                                <input type="datetime-local" v-model="promoRequest.startDate"
                                                    @blur="validationRule()" class="form-control" id="inputLong">
                                                <small class="text-danger">{{validationData.startDate}}</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputLong">End Date</label>
                                                <input type="datetime-local" v-model="promoRequest.endDate"
                                                    @blur="validationRule()" class="form-control" id="inputLong">
                                                <small class="text-danger">{{validationData.endDate}}</small>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-10">
                                                    <button type="submit" class="btn btn-primary mr-2">Save</button>
                                                    <a class="btn btn-danger text-white"
                                                        @click="cancelForm()">Cancel</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" hide-footer>
            <template v-slot:modal-title>
                Delete
            </template>
            <div class="d-block text-center">
                <p>Are you sure to delete?</p>
                <small class="text-danger">{{message}}</small>
            </div>
            <b-button class="mt-3 btn-danger" block @click="loactionDelete()">Delete</b-button>
        </b-modal>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import moment from 'moment';
    export default {
        name: 'promo',
        metaInfo: {
            title: "Promo",
            titleTemplate: "%s ← Htawara Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                message: "",
                promos: [],
                items: [],
                promoRequest: {
                    id: "",
                    promoType: "",
                    itemId: "",
                    promo: "",
                    startDate: "",
                    endDate: ""
                },
                validationData: {
                    promoType: "",
                    itemId: "",
                    promo: "",
                    startDate: "",
                    endDate: ""
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                ItemSelectAction: 'ItemSelectAction',
                PromoListAction: 'PromoListAction',
                PromoStoreAction: 'PromoStoreAction'
            }),
            async ItemSelect() {
                this.isLoading = true;
                await this.ItemSelectAction().then(res => {
                    if (res.status == "success") {
                        this.items = res.data
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            async promoList() {
                this.isLoading = true;
                await this.PromoListAction().then(res => {
                    if (res.status == "success") {
                        this.promos = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async onSubmit() {
                this.isLoading = true
                let validation = this.validationRule()
                if (validation == true) {
                    this.isLoading = false
                    return
                }
                let option = {
                    id: this.promoRequest.id,
                    promoType: this.promoRequest.promoType,
                    itemId: this.promoRequest.itemId,
                    promo: this.promoRequest.promo,
                    startDate: this.promoRequest.startDate,
                    endDate: this.promoRequest.endDate
                }
                await this.PromoStoreAction({
                    ...option
                }).then(res => {
                    this.promoList()
                    this.cancelForm()
                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
            editForm(promo) {
                this.promoRequest.id = promo.id
                this.promoRequest.promoType = promo.type
                this.promoRequest.itemId = promo.itemId
                this.promoRequest.startDate = moment(new Date(promo.startDate)).format('YYYY-MM-DDTHH:mm')
                this.promoRequest.endDate = moment(new Date(promo.endDate)).format('YYYY-MM-DDTHH:mm')
                if (promo.type == "percent") {
                    this.promoRequest.promo = promo.percent
                } else {
                    this.promoRequest.promo = promo.fix
                }
                console.log(this.promoRequest.startDate)
            },
            deleteForm(promo) {
                this.promoRequest.id = promo.id
                this.message = ""
            },
            cancelForm() {
                this.promoRequest.id = ""
                this.promoRequest.promoType = ""
                this.promoRequest.itemId = ""
                this.promoRequest.promo = ""
                this.promoRequest.startDate = ""
                this.promoRequest.endDate = ""
                this.validationData.promoType = ""
                this.validationData.itemId = ""
                this.validationData.promo = ""
                this.validationData.startDate = ""
                this.validationData.endDate = ""
            },
            validationRule() {
                let isValidation = []
                if (this.promoRequest.promoType == "") {
                    this.validationData.promoType = "The promo type field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.promoType = ""
                    isValidation.push(false)
                }
                if (this.promoRequest.itemId == "") {
                    this.validationData.itemId = "The item field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.itemId = ""
                    isValidation.push(false)
                }
                if (this.promoRequest.promo == "") {
                    this.validationData.promo = "The promo field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.promo = ""
                    isValidation.push(false)
                }
                if (this.promoRequest.startDate == "") {
                    this.validationData.startDate = "The start date field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.startDate = ""
                    isValidation.push(false)
                }
                if (this.promoRequest.endDate == "") {
                    this.validationData.endDate = "The end date field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.endDate = ""
                    isValidation.push(false)
                }
                if (isValidation.includes(true)) {
                    return true
                } else {
                    return false
                }
            }
        },
        filters: {
            moment: function (date) {
                return moment(date).format('YYYY-MM-DD, h:mm A');
            }
        },
        async mounted() {
            this.promoList()
            this.ItemSelect()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>