import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Admin from '../views/Admin'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/admin',
		name: 'admin',
		component: Admin,
		children: [{
			path: '/dashboard',
			name: 'dashboard',
			component: () => import('../views/Dashboard'),
		},
		{
			path: '/item-category-list',
			name: 'item-category-list',
			component: () => import('../views/itemcategory/ItemCategoryList'),
		},
		{
			path: '/item-list',
			name: 'item-list',
			component: () => import('../views/item/ItemList'),
		},
		{
			path: '/item-create',
			name: 'item-create',
			component: () => import('../views/item/ItemCreate'),
		},
		{
			path: '/item-detail/:id',
			name: 'item-detail',
			component: () => import('../views/item/ItemCreate'),
		},
		{
			path: '/item-inventory-detail/:id',
			name: 'item-inventory-detail',
			component: () => import('../views/item/ItemInventory'),
		},
		{
			path: '/item-log',
			name: 'item-log',
			component: () => import('../views/item/ItemLog'),
		},
		{
			path: '/location',
			name: 'location',
			component: () => import('../views/location/Location'),
		},
		{
			path: '/distributor-list',
			name: 'distributor-list',
			component: () => import('../views/distributor/DistributorList'),
		},
		{
			path: '/distributor-info',
			name: 'distributor-info',
			component: () => import('../views/distributor/DistributorInfo'),
		},
		{
			path: '/distributor-info/:id',
			name: 'distributor-info',
			component: () => import('../views/distributor/DistributorInfo'),
		},
		{
			path: '/distributor-contract/:id',
			name: 'distributor-contract',
			component: () => import('../views/distributor/DistributorContract'),
		},
		{
			path: '/distributor-user/:id',
			name: 'distributor-user',
			component: () => import('../views/distributor/DistributorUser'),
		},
		{
			path: '/distributor-price/:id',
			name: 'distributor-price',
			component: () => import('../views/distributor/DistributorPrice'),
		},
		{
			path: '/distributor-return/:id',
			name: 'distributor-return',
			component: () => import('../views/distributor/DistributorReturn'),
		},
		{
			path: '/pricerule-list',
			name: 'pricerule-list',
			component: () => import('../views/pricerule/PriceRule'),
		},
		{
			path: '/promo-list',
			name: 'promo-list',
			component: () => import('../views/promo/Promo'),
		},
		{
			path: '/sale-list',
			name: 'sale-list',
			component: () => import('../views/sale/SaleList'),
		},
		{
			path: '/return-list',
			name: 'return-list',
			component: () => import('../views/return/ReturnList'),
		},
		{
			path: '/consumer-sale-list',
			name: 'consumer-sale-list',
			component: () => import('../views/sale/ConsumerSaleList'),
		},
		{
			path: '/consumer-return-list',
			name: 'consumer-return-list',
			component: () => import('../views/return/ConsumerReturnList'),
		},
		{
			path: '/employee',
			name: 'employee',
			component: () => import('../views/employee/Employee'),
		},
		{
			path: '/member',
			name: 'member',
			component: () => import('../views/member/Member'),
		},
		]
	},

	{
		path: '/*',
		redirect: '/dashboard'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router