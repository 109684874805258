<template lang="html">
	<div class="container-fluid p-5">
		<div class="row">
			<div class="col-md-12">
				<div>
					<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
						<li class="nav-item">
							<router-link to="item-create" class="nav-link">Item Info</router-link>
						</li>
						<li class="nav-item">
							<router-link to="item-inventory" class="nav-link">Inventory</router-link>
						</li>
						<li class="nav-item">
							<router-link to="item-log" class="nav-link active">Item Log</router-link>
						</li>
					</ul>
				</div>
				<div class="card">
					<div class="card-header">
						<h6>New Item <small>(Fields in red are required)</small></h6>
					</div>
					<div class="card-body">
						<form>
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">Item Name</label>
								<div class="col-sm-10">
									<input type="text" class="form-control" id="inputName">
								</div>
							</div>
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">Category</label>
								<div class="col-sm-10">
									<select class="form-control" v-model="infoRequest.category">
										<option value="">Select Category</option>
									</select>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">Entry Date</label>
								<div class="col-sm-10">
									<input type="date" class="form-control" id="inputName">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-sm-2">Inactive</div>
								<div class="col-sm-10">
									<div class="form-check">
										<input class="form-check-input" type="checkbox" >
									</div>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Save</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	export default {
		name: 'itemLog',
		metaInfo: {
            title: "Item Log",
            titleTemplate: "%s ← Htawara Dashboard",
        },
		data() {
			return {
				infoRequest: {
					category: ""
				}
			}
		},
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
</style>