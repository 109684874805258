<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Category List <span class="badge badge-primary">{{categories.length}}</span>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="card mb-2" v-for="category in categories">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <h6 class="card-title">{{category.name}}</h6>
                                            </div>
                                            <div class="col-md-1">
                                                <button class="btn btn-option text-primary" @click="editForm(category)"><i class="fas fa-edit"></i></button>
                                            </div>
                                            <div class="col-md-1">
                                                <button @click="deleteForm(category)" v-b-modal.bv-modal-delete class="btn btn-option text-danger"><i class="fas fa-trash-alt"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card mb-2">
                                    <div class="card-body">
                                        <form v-on:submit.prevent="onSubmit">
                                            <div class="form-group">
                                                <label for="inputName">Category Name</label>
                                                <input type="text" v-model="categoryRequest.name"
                                                    @blur="validationRule()" class="form-control" id="inputName">
                                                <small class="text-danger">{{validationData.name}}</small>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-10">
                                                    <button type="submit" class="btn btn-primary mr-2">Save</button>
                                                    <a class="btn btn-danger text-white" @click="cancelForm()">Cancel</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" hide-footer>
            <template v-slot:modal-title>
                Delete
            </template>
            <div class="d-block text-center">
                <p>Are you sure to delete?</p>
                    <small class="text-danger">{{message}}</small>
            </div>
            <b-button class="mt-3 btn-danger" block @click="categoryDelete()">Delete</b-button>
        </b-modal>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'category',
        metaInfo: {
            title: "Category",
            titleTemplate: "%s ← Htawara Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                message: "",
                categories: [],
                categoryRequest: {
                    id: "",
                    name: "",
                },
                validationData: {
                    name: "",
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                ItemCategoryListAction: 'ItemCategoryListAction',
                ItemCategoryStoreAction: 'ItemCategoryStoreAction',
                itemCategoryDeleteAction: 'itemCategoryDeleteAction'
            }),
            async categoryList() {
                this.isLoading = true;
                await this.ItemCategoryListAction().then(res => {
                    if (res.status == "success") {
                        this.categories = res.data.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async onSubmit() {
                this.isLoading = true
                let validation = this.validationRule()
                if (validation == true) {
                    this.isLoading = false
                    return
                }
                let option = {
                    id: this.categoryRequest.id,
                    name: this.categoryRequest.name
                }
                await this.ItemCategoryStoreAction({
                    ...option
                }).then(res => {
                    this.categoryList()
                    this.cancelForm()
                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
            editForm(category) {
                this.categoryRequest.id = category.id
                this.categoryRequest.name = category.name
            },
            deleteForm(category) {
                this.categoryRequest.id = category.id
                this.message = ""
            },
            cancelForm() {
                this.categoryRequest.id = ""
                this.categoryRequest.name = ""
                this.validationData.name = ""
            },
            async categoryDelete() {
                this.isLoading = true
                let option = {
                    id: this.categoryRequest.id
                }
                await this.itemCategoryDeleteAction({
                    ...option
                }).then(res => {
                    if(res.status == 'fail'){
                        this.message = res.message
                    }else{
                        this.$refs['my-modal'].hide()
                    }
                    this.categoryList()
                    this.cancelForm()

                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
            validationRule() {
                let isValidation = []
                if (this.categoryRequest.name == "") {
                    this.validationData.name = "The category name field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.name = ""
                    isValidation.push(false)
                }
                if (isValidation.includes(true)) {
                    return true
                } else {
                    return false
                }
            }
        },
        async mounted() {
            this.categoryList()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>