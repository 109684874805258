<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div>
					<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
						<li class="nav-item">
							<a href="#" class="nav-link active">Distributor Info</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toContract()">Distributor Contract</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toUser()">Distributor User</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toPrice()">Price Rule</a>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toReturn()">Return Item</a>
						</li>
					</ul>
				</div>
				<div class="card">
					<div class="card-header">
						<h6>Distributor Info Form <small>(Fields in red are required)</small></h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputLocation" class="col-sm-2 col-form-label text-danger">Location</label>
								<div class="col-sm-10">
									<select v-model="infoRequest.locationId" @change="validationRule()"
										class="form-control" id="inputLocation">
										<option value="">Select Location</option>
										<option :value="location.id" v-for="location in locations">
											{{location.name}}</option>
									</select>
									<small class="text-danger">{{validationData.locationId}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">Name</label>
								<div class="col-sm-10">
									<input type="text" v-model="infoRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputEmail" class="col-sm-2 col-form-label text-danger">Email</label>
								<div class="col-sm-10">
									<input type="text" v-model="infoRequest.email" @blur="validationRule()"
										class="form-control" id="inputEmail">
									<small class="text-danger">{{validationData.email}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputPhone" class="col-sm-2 col-form-label text-danger">Phone</label>
								<div class="col-sm-10">
									<input type="text" v-model="infoRequest.phone" @blur="validationRule()"
										class="form-control" id="inputPhone">
									<small class="text-danger">{{validationData.phone}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputAddress" class="col-sm-2 col-form-label text-danger">Address</label>
								<div class="col-sm-10">
									<textarea v-model="infoRequest.address" class="form-control" id="inputAddress"
										rows="3"></textarea>
									<small class="text-danger">{{validationData.address}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputLat" class="col-sm-2 col-form-label text-danger">Latitude</label>
								<div class="col-sm-10">
									<input type="text" v-model="infoRequest.lat" @blur="validationRule()"
										class="form-control" id="inputLat">
									<small class="text-danger">{{validationData.lat}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputLong" class="col-sm-2 col-form-label text-danger">Longitude</label>
								<div class="col-sm-10">
									<input type="text" v-model="infoRequest.long" @blur="validationRule()"
										class="form-control" id="inputLong">
									<small class="text-danger">{{validationData.long}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputTextarea" class="col-sm-2 col-form-label">Descrption</label>
								<div class="col-sm-10">
									<textarea v-model="infoRequest.description" class="form-control" id="inputTextarea"
										rows="3"></textarea>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputPoint" class="col-sm-2 col-form-label">Point</label>
								<div class="col-sm-10">
									<input type="number" v-model="infoRequest.point" @blur="validationRule()"
										class="form-control" id="inputPoint">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-sm-2">Status</div>
								<div class="col-sm-10">
									<div class="form-check">
										<input v-model="infoRequest.status" class="form-check-input" type="checkbox">
									</div>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Save</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<b-modal ref="my-modal" hide-footer>
			<template v-slot:modal-title>
				Alert
			</template>
			<div class="d-block text-center">
				<p>You must fill out the required fields before making other changes.</p>
			</div>
		</b-modal>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'distributorInfo',
		metaInfo: {
            title: "Distri Info",
            titleTemplate: "%s ← Htawara Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				isLoading: false,
				fullPage: true,
				locations: [],
				infoRequest: {
					id: "",
					locationId: "",
					name: "",
					email: "",
					phone: "",
					address: "",
					lat: "",
					long: "",
					description: "",
					point: "",
					status: ""
				},
				validationData: {
					locationId: "",
					name: "",
					email: "",
					phone: "",
					address: "",
					lat: "",
					long: "",
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.infoRequest.id = to.params.id
					await this.distributorInfo()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				LocationListForSelectAction: 'LocationListForSelectAction',
				DistributorInfoStoreAction: 'DistributorInfoStoreAction',
				DistributorInfoAction: 'DistributorInfoAction'
			}),
			async locationSelect() {
				this.isLoading = true;
				await this.LocationListForSelectAction().then(res => {
					if (res.status == "success") {
						this.locations = res.data
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			async distributorInfo() {
				this.isLoading = true;
				await this.DistributorInfoAction(this.infoRequest.id).then(res => {
					if (res.status == "success") {
						this.infoRequest.locationId = res.data.location_id
						this.infoRequest.name = res.data.name
						this.infoRequest.email = res.data.email
						this.infoRequest.phone = res.data.phone
						this.infoRequest.address = res.data.address
						this.infoRequest.lat = res.data.lat
						this.infoRequest.long = res.data.long
						this.infoRequest.description = res.data.description
						this.infoRequest.point = res.data.point
						this.infoRequest.status = res.data.status
						this.isLoading = false
					}
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = {
					id: this.infoRequest.id,
					locationId: this.infoRequest.locationId,
					name: this.infoRequest.name,
					email: this.infoRequest.email,
					phone: this.infoRequest.phone,
					address: this.infoRequest.address,
					lat: this.infoRequest.lat,
					long: this.infoRequest.long,
					description: this.infoRequest.description,
					point: this.infoRequest.point,
					status: this.infoRequest.status
				}
				await this.DistributorInfoStoreAction({
					...option
				}).then(res => {
					if (res.status == "success") {
						console.log(res.data.id)
						this.$router.replace('/distributor-info/' + res.data.id)
						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},
			toContract() {
				if (this.infoRequest.id) {
					this.$router.replace('/distributor-contract/' + this.infoRequest.id)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			toUser() {
				if (this.infoRequest.id) {
					this.$router.replace('/distributor-user/' + this.infoRequest.id)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			toPrice() {
				if (this.infoRequest.id) {
					this.$router.replace('/distributor-price/' + this.infoRequest.id)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			toReturn() {
				if (this.infoRequest.id) {
					this.$router.replace('/distributor-return/' + this.infoRequest.id)
				} else {
					this.$refs['my-modal'].show()
				}
			},
			validationRule() {
				let isValidation = []
				if (this.infoRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.infoRequest.locationId == "") {
					this.validationData.locationId = "The location field is required."
					isValidation.push(true)
				} else {
					this.validationData.locationId = ""
					isValidation.push(false)
				}
				if (this.infoRequest.email == "") {
					this.validationData.email = "The email field is required."
					isValidation.push(true)
				} else {
					this.validationData.email = ""
					isValidation.push(false)
				}
				if (this.infoRequest.phone == "") {
					this.validationData.phone = "The phone field is required."
					isValidation.push(true)
				} else {
					this.validationData.phone = ""
					isValidation.push(false)
				}
				if (this.infoRequest.address == "") {
					this.validationData.address = "The address field is required."
					isValidation.push(true)
				} else {
					this.validationData.address = ""
					isValidation.push(false)
				}
				if (this.infoRequest.lat == "") {
					this.validationData.lat = "The latitude field is required."
					isValidation.push(true)
				} else {
					this.validationData.lat = ""
					isValidation.push(false)
				}
				if (this.infoRequest.long == "") {
					this.validationData.long = "The longitude field is required."
					isValidation.push(true)
				} else {
					this.validationData.long = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			if (this.$route.params.id) {
				this.infoRequest.id = this.$route.params.id
				this.distributorInfo()
			}
			await this.locationSelect()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
</style>